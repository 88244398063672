import React from 'react';
import {Switch, Redirect} from 'react-router-dom';
import RouteWrapper from './Route';

import Home from '../pages/home';

import FinOpe from '../pages/operation-terminee';
import Faq from '../pages/faq';
import Contact from '../pages/contact';
import MentionsLegales from "../pages/mentions-legales";
import JeParticipe from "../pages/je-participe";
import Attente from "../pages/attente";

const checkOpeStop = () => {

  //si la constante n'est pas défnie on retourne true
  if( ! ("REACT_APP_DATE_OPE_STOP" in process.env) )
    return false
  
  //let start_date_string = "05/06/2020".split("/");
  let stop_date_string = process.env.REACT_APP_DATE_OPE_STOP.split("/")
  let stop_date = new Date(stop_date_string[2],stop_date_string[1]-1,stop_date_string[0])
  let now_date = new Date()

  return (now_date >= stop_date)
}
const checkOpeStart = () => {

  //si la constante n'est pas défnie on retourne true
  if( ! ("REACT_APP_DATE_OPE_START" in process.env) )
    return true

  //let start_date_string = "05/06/2020".split("/");
  let start_date_string = process.env.REACT_APP_DATE_OPE_START.split("/")
  let start_date = new Date(start_date_string[2],start_date_string[1]-1,start_date_string[0])
  let now_date = new Date()

  return (now_date >= start_date)
}

export default function Routes() {

  let operation_terminee = checkOpeStop()
  let operation_demarree = checkOpeStart()

  return(
      <Switch>

        {/*visible si ope demarre*/}
        { operation_demarree && <RouteWrapper path="/" exact component={Home} /> }
        { operation_demarree && <RouteWrapper path="/faq" component={Faq} /> }
        { operation_demarree && <RouteWrapper path="/contact" component={Contact} /> }
        { operation_demarree && <RouteWrapper path="/mentions-legales" component={MentionsLegales} /> }
        { operation_demarree && <RouteWrapper path="/je-participe" component={JeParticipe} /> }
        { operation_demarree && <RouteWrapper path="/attente" component={Attente} /> }

        <RouteWrapper path="/operation-terminee" component={FinOpe} />

        {/*visible si ope terminee*/}
        { operation_terminee === true && <RouteWrapper path="/operation-terminee" component={FinOpe} /> }

        {/*visible si ope non demarree*/}
        { !operation_demarree && <RouteWrapper path="/attente" component={Attente} /> }

        {/* redirection */}
        { operation_demarree && !operation_terminee && <Redirect to="/" /> }
        { operation_terminee && <Redirect to="/operation-terminee" /> }
        { !operation_demarree && <Redirect to="/attente" /> }

      </Switch>
    )
}