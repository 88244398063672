import React from 'react';

//champs texte classique
const CustomUploadField = ({label, name, id=name, placeholder="",cta,notice, onchangeHandler = null, register, error, className=""} ) =>{

    label = label.replace("*","<span>*</span>");

    return(
        <div className={"form_row_wrapper"} id={"form_row_wrapper_"+name}>
            <div className={"label_wrapper"}>
                <label htmlFor={id} dangerouslySetInnerHTML={{__html: label}}/>
                <span className={"upload_notice"} dangerouslySetInnerHTML={{__html : notice}}/>
            </div>
            <div className={"input_wrapper upload_wrapper"}>
                <input type="file" className="d-none"id={id+"_file"} name={name+"_file"} accept="image/png, image/jpeg, .pdf" onChange={onchangeHandler}/>
                <input className={className} type={"text"} id={id} name={name} placeholder={placeholder} ref={register} readOnly={true}/>
                <label id={id+"_cta"} htmlFor={id+"_file"}>{cta}</label>
                <div className="error">{error}</div>
            </div>
        </div>
    );
}

export default CustomUploadField;
