import React from 'react';

const Contact = () => {

  return (
    <>
      <div id="contactStrate1" className={"strate"}>
        <h1>Vous avez des questions <br className={"d-lg-none"}/>ou besoin de plus <br className={"d-lg-none"}/>d’informations ?</h1>
        <div className={"subtitle"}>Le Service Consommateurs <br className={"d-lg-none"}/>reste à votre disposition !</div>

        <div id={"contact_item_wrapper"}>

          <div className={"contact_item"} id={"contact_item_phone"}>
            <div className={"content"}>
              <div className={"title"}>Par téléphone au</div>
              <div className={"contact_link"}><a href={"tel:0971070781"}>09 71 07 07 81</a></div>
              <div className={"detail"}>
                Appel non surtaxé, prix d’un appel local, tarif en <br className={"d-lg-none"}/>
                vigueur <br className={"d-lg-block d-none"}/>selon opérateur.  Ligne active du lundi au <br className={"d-lg-none"}/>
                vendredi, de 9h30 à 17h30,<br className={"d-lg-block d-none"}/> hors jours fériés

              </div>
            </div>
          </div>

          <div className={"contact_item"} id={"contact_item_mail"}>
            <div className={"content"}>
              <div className={"title"}>
                Par e-mail à
              </div>
              <div className={"contact_link"}>
                <a href={"mailto:canon-rentree2023@tlcrewards.com?subject=Op%C3%A9ration%E2%80%AF%C2%AB%E2%80%AFRentr%C3%A9e%20Ast%C3%A9rix%202023%E2%80%AF%C2%BB%20de%20Canon%20%2F%20Votre%20nom%20et%20pr%C3%A9nom"}>canon-rentree2023@tlcrewards.com</a>
              </div>
              <div className={"detail"}>
                Objet : Opération « Rentrée Astérix 2023 » de Canon /<br/>Votre nom et prénom
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};


export default Contact;
