import React from 'react';
import {HashLink as Link} from 'react-router-hash-link';
import {NavLink} from "react-router-dom";

const Footer = () => {

  return (
      <footer>

        <div id={"footer_wrapper"}>

          <div className={"footer_logo_wrapper d-lg-none"}>
              <a id={"footer_logo_partenaire"} href={"https://www.canon.fr/"} target={"_blank"}/>
              <a id={"footer_logo_tlc"} href={"https://www.tlcmarketing.com/france"} target={"_blank"}/>
          </div>

          <div className={"footer_menu_wrapper"}>
            <div className={"footer_menu"}>
                <a id={"footer_logo_partenaire"} href={"https://www.canon.fr/"} target={"_blank"}/>
                <Link to="/faq">FAQ</Link>
                <Link to="/contact">Contact</Link>
                <a href="/file/Règlement.pdf" target={"_blank"}>Règlement du jeu</a>
                <Link to="/mentions-legales">Informations légales</Link>
                <a href="https://www.canon.fr/privacy-policy/" target={"_blank"}>Politique de Confidentialité</a>
                <a href="/file/Politique%20de%20cookies.pdf" target={"_blank"}>Politique de Cookies</a>
                <a id={"footer_logo_tlc"} href={"https://www.tlcmarketing.com/france"} target={"_blank"}/>
            </div>
          </div>

          <div className={"footer_mention"}>
            <div>asterix®-obelix®-idefix® <br className={"d-lg-none"}/><span className={"d-none d-lg-inline"}>/</span> ©2023 les éditions albert rené/goscinny - uderzo</div>
          </div>
        </div>
      </footer>

  );
};

export default Footer;
