import React from 'react';
import Contact from "./contact";

const MentionsLegales = () => {
  return (
    <>
      <div className={"strate"} id={"mlStrate1"}>

        <h1>Informations légales</h1>

        <div id={"ml_wrapper"}>
          <div className="block">
            <h4>Ce site est édité et hébergé par :</h4>

            <p>
              TLC Marketing<br/>
              92 avenue de Wagram – 75017 Paris<br/>
              N° SIREN : 491 414 306<br/>
              N° SIRET : 491 414 306 00036<br/>
              APE : 7311Z<br/>
              RCS : Paris B 491 414 306<br/>
              SARL au capital social de : 150 000€<br/>
              Numéro de TVA intracommunautaire : FR 70 491 414 306<br/>
              Adresse de courrier électronique : <br className={"d-lg-none"}/>
              <a href="mailto:bonjour@tlcmarketing.com">bonjour@tlcmarketing.com</a>
            </p>
          </div>
          <div className="block">
            <h4>Pour le compte de :</h4>
            <p>
              Canon France<br/>
              14 rue Emile Borel - CS 28646 - 75809 Paris Cedex<br/>
              N° SIREN : 738 205 269<br/>
              N° SIRET : 738 205 269 01882<br/>
              APE : 7733Z<br/>
              RCS : Paris B 738 205 269<br/>
              Capital social de : 141.940.032,00€<br/>
              Numéro de TVA intracommunautaire : <br className={"d-lg-none"}/>
              FR 89 738 205 269
            </p>
          </div>

          <div className={"smallBlock"}>
            <h4>La conception, le design et le développement du site ont été effectués par les agences</h4>
            <p>TLC Marketing et Cloud Media.</p>
          </div>

        </div>
      </div>
      <Contact/>
    </>

  );
};

export default MentionsLegales;


