import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Carousel, Modal} from "react-bootstrap";
import Contact from "./contact";

const Home = () => {

    const [showModal, setShowModal] = useState(false);
    const [modalDotation, setModalDotation] = useState(1);

    const createCarouselHowTo = () => {

        let items = [];
        const content = [
            [
                "Achetez",
                [
                    "un <a href='/file/Produits%20éligibles.pdf' target='_blank'>produit Canon <b>éligible à l’offre</b></a><br class='d-lg-none'/> en magasin ou<br class='d-lg-block d-none'/> en ligne pendant la<br class='d-lg-none'/> période promotionnelle, entre le<br/> <b>01/08/2023</b> et le <b>31/10/2023</b>."
                ]
            ],
            [
                "Enregistrez",
                [
                    "votre participation (nom, prénom,<br class='d-lg-none'/> adresse e-mail, date de<br/> naissance, preuve d’achat, et<br class='d-lg-none'/> photo du code-barres découpé<br/> de votre produit) <a href='/je-participe' target='_blank'><b>ici avant le<br class='d-lg-none'/> 01/12/2023</b></a>."
                ]
            ],
            [
                "Jouez avec Astérix",
                [
                    "et découvrez immédiatement si<br class='d-lg-none'/> vous avez gagné <b>1 séjour au<br class='d-lg-block d-none'/> Parc<br class='d-lg-none'/> Astérix, un lot de 4 entrées</b> ou<br class='d-lg-none'/> <b>1 activité loisir</b>."
                ]
            ],
            [
                "Vous recevrez",
                [
                    "<b>sous un délai de 4 semaines <br class='d-lg-none'/>maximum,</b> suite à la validation de <br/>votre participation, un e-mail de <br class='d-lg-none'/>confirmation contenant les <br/>informations liées à la dotation <br class='d-lg-none'/>remportée."
                ]
            ]
        ];

        for (let i = 0; i < 4; i++) {

            items.push(
                <Carousel.Item key={"HowToItem" + i}>
                    <div className={"carousel_howto_item"} id={"carousel_howto_item" + (i + 1)}>
                        <div className={"picture"}/>
                        <div className={"title"} dangerouslySetInnerHTML={{__html : content[i][0]}}/>
                        <div className={"content"}>
                            {content[i][1].map(text => (<p dangerouslySetInnerHTML={{__html: text}}/>))}
                        </div>
                    </div>
                </Carousel.Item>
            );
        }

        return (
            <div id={"home_howto_carousel_wrapper"}>
                <Carousel controls={true} interval={4000} indicators={true}>
                    {items}
                </Carousel>
            </div>
        );
    };
    const createCarouselDotation = () => {

        const mixpanel_code = ["Un séjour pour 4 personnes_Result count","4 entrées pour le parc Astérix_Result count","1 activité Loisir_Result count"]
        let items = [];

        for (let i = 0; i < 3; i++) {

            items.push(
                <Carousel.Item key={"DotationItem" + i}>
                    <div className={"carousel_dotation_item"} id={"carousel_dotation_item" + (i + 1)}>
                        <div className={"picture"}/>
                        <a href={"#0"} onClick={() => {

                            window.mixpanelhandler.track("Participation_Homepage", {[mixpanel_code[i]] : 1});

                            setModalDotation(i + 1)
                            setShowModal(true)
                        }}>Plus d'info</a>
                    </div>
                </Carousel.Item>
            );
        }

        return (
            <div id={"home_dotation_carousel_wrapper"} className={"d-lg-none"}>
                <Carousel controls={true} interval={2000} indicators={true}>
                    {items}
                </Carousel>
            </div>
        );
    };

    return (
        <>
            <div id={"homeStrate1"} className={"strate"}>
                <p>du 1<sup>er</sup> août au 31 octobre 2023 pour l’achat<br/><b>d’une imprimante</b> ou <b>d’un pack de cartouches</b></p>
            </div>
            <div id={"homeStrate2"} className={"strate"}>
                <Link className={"cta yellow"} to={"/je-participe"} onClick={ () => window.mixpanelhandler.track("Participation_Homepage", {"Je participe_Result count" : 1})}>je participe !</Link>
                <p>Offre soumise à condition, limitée à 3 participations par <br className={"d-lg-none"}/>
                    personne (nom, prénom, adresse e-mail) et pour l’achat de <br className={"d-lg-none"}/>
                    3 produits éligibles maximum.<br/>
                    <a href={"/file/Règlement.pdf"} target={"_blank"}>Voir le Règlement du Jeu ici</a>. Visuel non contractuel.
                </p>
                <p>
                    asterix®-obelix®-idefix® <br className={"d-lg-none"}/><span className={"d-lg-inline d-none"}>/ </span>
                    ©2023 les éditions albert rené/goscinny - uderzo
                </p>
            </div>
            <div id={"homeStrate3"} className={"strate"}>

                {createCarouselHowTo()}

                <div className={"text-center"}>
                    <Link className={"cta red"} to={"/je-participe"}>Je participe</Link>
                </div>
            </div>
            <div id={"homeStrate4"} className={"strate"}>
                {createCarouselDotation()}

                <div id={"dotation_wrapper"} className={"d-none d-lg-flex"}>

                    <div className={"carousel_dotation_item"} id={"carousel_dotation_item1"}>
                        <div className={"picture"}/>
                        <a href={"#0"} onClick={() => {
                            window.mixpanelhandler.track("Participation_Homepage", {"Un séjour pour 4 personnes_Result count" : 1});
                            setModalDotation(1)
                            setShowModal(true)
                        }}>Plus d'info</a>
                    </div>
                    <div className={"carousel_dotation_item"} id={"carousel_dotation_item2"}>
                        <div className={"picture"}/>
                        <a href={"#0"} onClick={() => {
                            window.mixpanelhandler.track("Participation_Homepage", {"4 entrées pour le parc Astérix_Result count" : 1});
                            setModalDotation(2)
                            setShowModal(true)
                        }}>Plus d'info</a>
                    </div>
                    <div className={"carousel_dotation_item"} id={"carousel_dotation_item3"}>
                        <div className={"picture"}/>
                        <a href={"#0"} onClick={() => {
                            window.mixpanelhandler.track("Participation_Homepage", {"1 activité Loisir_Result count" : 1});
                            setModalDotation(3)
                            setShowModal(true)
                        }}>Plus d'info</a>
                    </div>

                </div>

                <div className={"text-center"}>
                    <Link className={"cta red"} to={"/je-participe"}>Je participe</Link>
                </div>
            </div>
            <Contact/>

            <Modal className={"modal_dotation"} id={`modal_dotation_${modalDotation}`} show={showModal} onHide={() => setShowModal(false)} centered>
                <a href={"#0"} onClick={() => setShowModal(false)} id={"modal_link_close"}>Retour</a>
                <a href={"/file/Règlement.pdf"} target={"_blank"} id={"modal_link_ml"}/>
                <a href={"https://www.france.rewardsforall.com/"} target={"_blank"} id={"modal_link_rfa"}/>
            </Modal>

        </>
    );
};


export default Home;
