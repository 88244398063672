import React from 'react';
//import MixpanelContext from "react-mixpanel/lib/MixpanelContext";

import Contact from "./contact";
const FinOpe = () => {

	//const mixpanel = React.useContext(MixpanelContext);

	return(
		<>
			<div id="strate1" className={"strate first header_global1"}>
				<p>Retrouvez toutes les infos à propos<br className={"d-lg-none"}/> de Canon sur <br className={"d-lg-block d-none"}/><a href={"https://www.canon.fr"} target={"_blank"}>www.canon.fr</a></p>
			</div>

			<Contact/>
		</>
	);
}
export default FinOpe;
